<template>
  <div>
    <page-layout v-if="status === 'APPROVEDOVER'">
      <!-- ETC开票 -->
      <div class="pt-24">
        <!-- 搜索头 -->
        <search-header
          :schemaData="searchFileds"
          @getDataList="handleFormSearch"
        />

        <!-- 按钮 -->
        <div class="bg-white mx-24 pb-16">
          <div class="w500 d-flex align-center  px-24 py-16">
            <a-button
              v-if="$store.getters.roles.includes('KakeInvoiceExport')"
              class="mr-4 d-flex justify-center align-center"
              @click="handleBatchExoort"
            >
              <Icon
                name="icondaochu1"
                width="15"
                height="15"
                class="mr-4"
              ></Icon>
              批量导出
              <a-tooltip
                class="ml-8"
                placement="top"
                :overlayStyle="{ width: '320px' }"
                v-if="$store.getters.roles.includes('KakeInvoiceExport')"
              >
                <template #title>
                  <span class="w300">
                    由于部分运单结算开票时， ETC发票尚未全额开出，
                    由司机费用补全 ETC费用开出足额发票，因此运
                    单后续开出的ETC发票原则上不可用于企业进项抵扣，
                    导出的ETC发票可能包含不可抵扣的部分
                  </span>
                </template>
                <p style="display: inline-block">
                  <QuestionCircleOutlined
                    class="fz-14"
                    style="color: #999999"
                  />
                </p>
              </a-tooltip>
            </a-button>

            <a-button
              v-if="$store.getters.roles.includes('KakeInvoiceDownload')"
              class="ml-16 mr-16 d-flex justify-center align-center"
              @click="handleShowDownload"
            >
              <Icon
                name="iconxiazai"
                width="15"
                height="15"
                class="mr-4"
              ></Icon>
              下载ETC发票
            </a-button>
            <!-- <a-button
              class="ml-16 mr-16 d-flex justify-center align-center"
              @click="handleDownloadTable"
            >
              <Icon
                name="iconxiazai"
                width="15"
                height="15"
                class="mr-4"
              ></Icon>
              下载表格
            </a-button> -->
            <p style="color: #808080; min-width: 500px">
              价税合计：{{ totalAmountO }}元; 可抵扣税额合计：{{
                totalTaxAmount
              }}元
            </p>
          </div>
          <div
            class="d-flex align-center mx-24"
            style="background:rgba(22,132,251,0.06);"
          >
            <Icon
              name="iconbiaodanneitishiicon"
              width="25"
              height="25"
              class="mx-16"
            ></Icon>
            <span>已选择{{ dataSource.meta.selectedRows.length }}项</span>
            <span class="mx-16">合计：{{ totalMoney }}元</span>
            <!-- <div>
              <a-button
                @click="$refs.xTable1.setAllCheckboxRow(true)"
                class="ml-8 mr-16"
                type="link"
                style="padding:0;"
                >选择全部</a-button
              >
              <a-button type="link" @click="cancelAllEvent">取消选择</a-button>
            </div> -->
          </div>
        </div>
        <!-- 列表 -->
        <div class="mx-24 bg-white">
          <p-table
            dataKey="modal"
            ref="xTable1"
            rowKey="id"
            :dataSource="dataSource"
            :columns="tableColumns"
            :checkbox-config="{
              highlight: true
            }"
          >
            <!-- <template #footer> </template> -->
          </p-table>
        </div>
      </div>
    </page-layout>
    <Filing
      v-if="status && status !== 'APPROVEDOVER' && status !== 'NORECORDFill'"
      :status="status"
      @changeStatus="changeStatus"
    ></Filing>
    <FilingInfo
      v-if="status === 'NORECORDFill'"
      @changeStatus="changeStatus"
    ></FilingInfo>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from "vue";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import { searchFileds } from "./constants/index";
import { getColumns } from "./constants/table-config";
import { message } from "ant-design-vue";
import {
  useInvoicePageApi,
  useInvoiceExportApi,
  useEtcQueryApi,
  useInvoiceDownloadApi,
  useInvoiceExportTableApi
} from "@/apis/etc";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import useSearchForm from "@/hooks/UseSearchForm";
import Filing from "./components/Filing.vue";
import FilingInfo from "./components/FilingInfo.vue";

export default defineComponent({
  name: "make-invoice",
  components: {
    QuestionCircleOutlined,
    Filing,
    FilingInfo
  },
  setup() {
    const totalAmountO = ref(0);
    const totalTaxAmount = ref(0);
    const tableColumns = getColumns();
    const tableDataSource = reactive(getInitTable());
    const { filter, handleFormSearch } = useSearchForm(searchFileds);

    const invoicePage = useInvoicePageApi();
    const invoiceExport = useInvoiceExportApi();

    const tableData = ref([]);

    const getConsultingList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        let { external_id, ...config } = filter.value;
        const data = {
          external_id: external_id === null ? [] : [external_id],
          ...config,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await invoicePage(data);
        tableData.value = res.list; //保存数据当页数据
        totalAmountO.value = res.ext.total_amounts / 100;
        totalTaxAmount.value = res.ext.total_tax_amounts / 100;
        return { records: res.list, total: res.total };
      }
    });

    // 批量导出按钮
    const handleBatchExoort = async () => {
      const selectedRows = tableDataSource.meta.selectedRows;
      const idList = [];
      let { external_id, ...config } = filter.value;
      selectedRows.map(item => {
        idList.push(item.id);
      });
      const res = await invoiceExport({
        ids: idList,
        ...config,
        external_id: external_id === null ? [] : [external_id]
      });
      if (res) {
        message.success("导出成功，请前往下载中心查看");
      }
    };

    // 下载表格按钮
    const invoiceExportTableApi = useInvoiceExportTableApi();
    const handleDownloadTable = async () => {
      const selectedRows = tableDataSource.meta.selectedRows;
      const idList = [];
      let { external_id, ...config } = filter.value;
      selectedRows.map(item => {
        idList.push(item.id);
      });
      const res = await invoiceExportTableApi({
        ids: idList,
        ...config,
        external_id: external_id === null ? [] : [external_id]
      });
      if (res) {
        message.success("下载成功，请前往下载中心查看");
      }
    };

    //状态查询
    const EtcQuery = useEtcQueryApi();
    const status = ref("");
    // NORECORD :未备案
    // NORECORDFill :填写备案信息
    // PENDING: 备案中
    // APPROVED: 备案通过
    // APPROVEDOVER: 已点击开始开始使用
    // FAILED: 备案失败
    // CANCEL: 已销户
    const computedStatus = res => {
      if (res.list.length === 0) {
        return "NORECORD";
      } else {
        if (res.list[0].status === "APPROVED") {
          if (res.list[0].ext_json?.used === true) {
            return "APPROVEDOVER";
          } else {
            return "APPROVEDOVER";
          }
        } else {
          return res.list[0].status;
        }
      }
    };
    EtcQuery().then(res => {
      status.value = computedStatus(res);
    });
    const changeStatus = statu => {
      status.value = statu;
    };

    //通过watch的方式来响应表格勾选数据变化
    const totalMoney = ref(0);
    watch(
      () => tableDataSource.meta.selectedRows,
      newValue => {
        //统计勾选的数据
        totalMoney.value = 0;
        newValue.forEach(item => {
          if (item.total_amount) {
            totalMoney.value += item.total_amount;
          }
        });
        totalMoney.value = (totalMoney.value / 100).toFixed(2);
      }
    );

    //全部选择
    const selectAllEvent = () => {
      tableDataSource.meta.selectedRows = [];
      tableDataSource.meta.selectedRows = tableData.value;
    };
    //全部取消
    const cancelAllEvent = () => {
      tableDataSource.meta.selectedRows = [];
    };

    //发票下载ETC发票
    const unvoiceDownload = useInvoiceDownloadApi();
    const handleShowDownload = () => {
      const selectedRows = tableDataSource.meta.selectedRows;
      const idList = [];
      if (selectedRows.length !== 0) {
        selectedRows.map(item => {
          idList.push(item.id);
        });
      }
      let { external_id, ...config } = filter.value;
      const data = {
        external_id: external_id === null ? [] : [external_id],
        ...config,
        ids: idList,
        size: tableDataSource.meta.pageSize,
        page: tableDataSource.meta.currentPageNo
      };
      unvoiceDownload(data).then(() => {
        message.success("导出成功，请前往下载中心查看");
      });
    };

    return {
      tableColumns,
      searchFileds,
      totalAmountO,
      totalTaxAmount,
      handleFormSearch,
      getConsultingList,
      handleBatchExoort,
      handleDownloadTable,
      dataSource: tableDataSource,
      status,
      changeStatus,
      selectAllEvent,
      handleShowDownload,
      totalMoney,
      cancelAllEvent
    };
  }
});
</script>
<style lang="less" scoped>
:deep(.vxe-table--render-default) .vxe-body--row.row--checked,
.vxe-table--render-default .vxe-body--row.row--radio {
  background: rgba(22, 132, 251, 0.15);
}
</style>
