import { buildApi } from "@/utils/ajax";
import { GetRequest } from "@/utils/requestUrl";
const url = GetRequest();
const basicApi = buildApi("/portal/company");

// 合同管理
// 登录生成合同
export const useContractGenerateApi = basicApi({
  url: "/contract/generate",
  params: url && url ? { token: GetRequest() } : "",
  withCredentials: true
});

// 合同签署列表
export const useContractSignPageApi = basicApi({
  url: "/contract/sign/page",
  withCredentials: true
});

// 合同预览
export const useContractPreviewApi = basicApi({
  url: "/contract/preview",
  withCredentials: true
});

// 合同签署
export const useContractSignApi = basicApi({
  url: "/contract/sign",
  withCredentials: true
});

// 文件下载
export const useContractDownloadApi = basicApi({
  url: "/oss/download",
  withCredentials: true,
  responseType: "blob",
  meta: { isTransformRequestResult: false }
});
