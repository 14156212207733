/**
 * 搜索头
 */
export const searchFileds = [
  {
    type: "input",
    field: "external_id",
    label: "关联运单号",
    iw: 200
  },
  {
    type: "input",
    field: "plate_number",
    label: "车牌",
    iw: 200
  },
  {
    field: ["start_invoice_time", "end_invoice_time"],
    label: "开票时间",
    type: "dateRange",
    props: {
      // placeholder: "请选择时间",//['请选择时间','请选择时间']
      dateFormat: "YYYY/MM/DD"
    },
    iw: 320
  },
  {
    type: "select",
    field: "zero_flag",
    label: "可抵扣税额",
    options: [
      { value: false, label: "不为0" },
      { value: true, label: "为0" }
    ],
    iw: 200
  }
];
