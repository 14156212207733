import BigNumber from "bignumber.js";
//table列配置
export const getColumns = () => {
  const columns = [
    {
      width: 60,
      type: "checkbox",
      fixed: "left",
      align: "left"
    },
    {
      width: 60,
      type: "seq",
      field: "$$id",
      fixed: "left",
      align: "left",
      title: "序号"
    },
    {
      title: "关联运单号",
      dataIndex: "external_id",
      minWidth: 120
    },
    {
      title: "运单创建时间",
      field: "order_start_time",
      minWidth: 120,
      cellRender: "p-date"
    },
    {
      title: "运单结束时间",
      field: "order_end_time",
      minWidth: 120,
      cellRender: "p-date"
    },
    {
      title: "车牌",
      dataIndex: "plate_number",
      minWidth: 120
    },
    {
      title: "上站点",
      dataIndex: "en_station",
      minWidth: 120
    },
    {
      title: "下站点",
      dataIndex: "ex_station",
      minWidth: 120
    },
    {
      title: "销方名称",
      dataIndex: "seller_name",
      minWidth: 120
    },
    {
      title: "价税合计金额(元)",
      field: "total_amount",
      minWidth: 140,
      align: "right",
      cellRender: "p-money"
    },
    {
      title: "可抵扣税额",
      field: "total_tax_amount",
      minWidth: 120,
      align: "right",
      customRender: (_, row) => {
        if (row.total_tax_amount) {
          const result = new BigNumber(row.total_tax_amount)
            .dividedBy(100)
            .toFixed(2, BigNumber.ROUND_DOWN);
          return <span>{result}</span>;
        } else {
          return <span>0</span>;
        }
      }
    },
    {
      title: "开票时间",
      field: "invoice_time",
      minWidth: 120,
      cellRender: "p-date"
    }
  ];
  return columns;
};
