<template>
  <div style="min-width:1116px;overflow:auto;height:calc(100vh - 64px)">
    <div class="ma-24 d-flex filing" style="height:calc(100% - 48px);">
      <div
        style="width: 500px"
        class="flex-grow-1 bg-white text-center pa-24 d-flex flex-column"
      >
        <div v-if="status === 'NORECORD'" class="flex-grow-1">
          <img
            style="height: 30%;margin-top: 15%;margin-bottom: 10%;"
            src="http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/etc/NORECORD.svg"
            alt=""
          />
          <p>如果您的企业需要开通ETC开票服务，请先申请备案</p>
          <a-button
            type="primary"
            class="mt-16 mb-24"
            @click="changeStatus('NORECORDFill')"
          >
            申请备案
          </a-button>
        </div>
        <div v-if="status === 'PENDING'" class="flex-grow-1">
          <img
            style="height: 30%;margin-top: 15%;margin-bottom: 10%;"
            src="http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/etc/PENDING.svg"
            alt=""
          />
          <p class="mb-80 mt-69" style="color:#808AA4;">
            您的企业正在备案审核中...
          </p>
        </div>
        <div v-if="status === 'APPROVED'" class="flex-grow-1">
          <img
            style="height: 30%;margin-top: 15%;margin-bottom: 10%;"
            src="http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/etc/APPROVED.svg"
            alt=""
          />
          <p>您的企业已开通ETC开票服务</p>
          <a-button type="primary" class="mt-16 mb-24" @click="useEtcHandler">
            点击开始使用
          </a-button>
        </div>
        <div class=" flex-grow-1">
          <div class="pa-16 text-left" style="background-color:#F8F9FB;">
            <h3>ETC开票服务：</h3>
            <p>
              是一项旨在帮助企业获取本企业下运单所产生的合理的ETC发票的数据服务。开通该服务后，企业再通过网络货运平台上传新运单时，会自动上传运单信息获取对应的ETC发票。获取到的发票可以在增值服务平台企业端【ETC开票】页面查看，可以下载PDF做财务凭证，也可以批量导出到企业金税盘登陆的增值税发票综合服务平台做批量进项抵扣。
            </p>
          </div>
        </div>
      </div>
      <div style="width: 400px" class="bg-white ml-24 px-24 py-20 flex-grow-1">
        <h4 class="pb-20">服务F&Q</h4>
        <ul class="lh-16">
          <li class="ask" v-for="(item, index) in fq" :key="index">
            <p class="d-flex pl-24">
              <span>问：</span>
              <span>{{ item.f }}</span>
            </p>
            <p class="d-flex pl-24 mt-8 mb-24" style="color:#808080;">
              <span>答：</span>
              <span>{{ item.q }}</span>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { useEtcUseApi } from "@/apis/etc";
import { state } from "@/store/modules/user";
export default defineComponent({
  props: {
    status: {
      required: true,
      type: String
    }
  },
  setup(props, ctx) {
    const EtcUse = useEtcUseApi();
    const useEtcHandler = () => {
      EtcUse({ company_id: state.companyId }).then(() => {
        ctx.emit("changeStatus", "APPROVEDOVER");
      });
    };
    const changeStatus = statu => {
      ctx.emit("changeStatus", statu);
    };
    const fq = [
      {
        f: "企业的授权函过期了，服务会停用吗?",
        q:
          "服务不会自动停用，运营人员会通知企业续签权涵，如果企业拒签，运营人ß员有权停止开票服务。"
      },
      {
        f: "企业的余额用完了，服务会停用吗？",
        q:
          "如果企业的通用余额/对应服务消费卡和储值账户均不足的情况下，无法调用服务，请及时充值。"
      },
      {
        f: "如果企业地址、联系人、联系方式等信息发生变更怎么办？",
        q: "请及时联系运营人员张松华进行信息变更。"
      },
      {
        f:
          "票开出来一次后只扣一次费用吗？后面在请求运单查询发票信息还会扣费吗？",
        q: "我们的收费规则一条交易只收1次费用。"
      },
      {
        f: "收费是怎么收的？",
        q:
          "按运单匹配到交易收费用，一条交易（每次上下高速生成一条交易记录，一个运单可能包含多个交易）收费1.5元。（匹配到交易就开始计费，那个月分匹配到的，就算到那个月的账单中）"
      },
      {
        f: "扣费是在我们哪一步操作后进行的?",
        q:
          "扣费是在您上传了结束时间后就会自动进行发票匹配，只要匹配到就会扣费。"
      },
      {
        f: "获取的发票是纸质还是电子发票？",
        q: "通行费增值税普通电子发票。格式为PDF "
      },
      {
        f:
          "假如同时有两家在上传一趟的ETC发票，那开给公司A还是公司B是以上传结束为准  还是以开票为准？",
        q: "谁先开票就是谁的。"
      },
      {
        f: "上传一批运单最后开出的发票是都合计在一张上面吗？",
        q:
          "上传一批运单由于道路营运方不同，税率不同会形成多个交易id（通常一个交易id会对应2-4张发票）所以最后会开出很多发票。"
      },
      {
        f: "为什么进出一次高速会开出多张发票？",
        q: "道路经营管理单位不同，每家道路业主提供各种的发票，所以有多张票。"
      },
      {
        f: "开的发票的税率是多少？",
        q: "开的发票的税率是3%、5%、9%"
      },
      {
        f: "为什么税额不同呢？",
        q:
          "1.因为高速公路通行费抵扣进项税额=高速公路通行费发票上注明的金额÷（1+3%）×3%。2.2016年4月30日后施工建造的高速公路为税率为9%。3.一级公路、二级公路、桥、闸通行费可抵扣进项税额=一级公路、二级公路、桥、闸通行费发票上注明的金额÷（1+5%）×5%。"
      },
      {
        f: "发票是谁开出来的？",
        q: "路网中心开"
      },
      {
        f: "上传运单后未开出发票是什么原因？",
        q:
          "上传成功未匹配上交易有几种情况：1、司机现金交费；没使用ETC卡，所以没有开票交易。2、用户刷卡了，但卡的可开票余额不足，原因：用户开具了充值发票或者发行方充值交易未上传。3、用户刷卡了，可开票余额充足，但省中心交易还没上传。4、通行费交易可能被开走了。"
      },
      {
        f: "为什么刚刚上传的实时运单没有开票？",
        q:
          "运单开票规则：1、实时运单开票：运单上传开始时间与运单开始时间应小于72小时，运单开始时间与运单结束时间小于12天；运单结束开票1次，结束10日开票1次，20日开票1次，共开具3次。2、实时运单第一次上传后系统自动匹配交易，等运单结束调取发票数据就行了，等20天在查询发票信息就可以。"
      },
      {
        f:
          "实时运单在第二次上传结束时间后，部分发票没有开出的原因？已结束运单、未结束和开票中代表的含义。",
        q:
          "已结束运单：未结束和开票中代表的含义  1未结束运单:只上传了开始运单。2已结束运单：上传了运单开始和运单结束指令。3实时运单在第二次上传结束时间后，部分发票没有开出的原因，显示开票中。因道路业主信息变更。"
      },
      {
        f: "客户可以选择低于30/50元的etc票不要吗",
        q:
          "扣多少钱，票有几张，金额多少，都是开完票之后才知道，同城的是不能开票的，系统会过滤掉。"
      },
      {
        f: "ETC发票是哪种发票，可以做进项抵扣吗？",
        q:
          "ETC票是普票，但是可以做进项抵扣，因为它是通行费，发票左上角有“通行费”字样，是一种特殊的抵扣项。"
      },
      {
        f: "对于1.5元的收费，那么一趟活儿交多少ETC费用才是划算的？",
        q:
          "按3%税率计算，一次调用获取的ETC发票加税合计金额在50元以上，可抵扣税额能够超过调用成本。按5%税率计算，一次调用获取的ETC发票加税合计金额在30元以上，可抵扣税额能够超过调用成本。但加上开票能够减少的企业所得税以及股东的个人所得税，会比单独做进项抵扣更划算。"
      },
      {
        f: "哪些运单不会拉ETC票？",
        q:
          "①判断车牌颜色，把黄色车牌的运单筛选出来上传；②实时单：运单开始时间跟实际运单上传时间不能超过72小时，所以只上传72小时之内的运单；③上传时间：开单指派司机后就可上传信息；④判断省市区，开始城市和目的地城市为一样的运单不传；⑤运单开始信息上传后，运单信息有更改，只要运单开始时间跟实际运单上传时间没超过72小时，则需要重新上传。"
      },
      {
        f: "如果开出来的ETC发票是别的公司的怎么办？",
        q:
          "在网络货运业务中，存在归属第三方企业的车辆和司机拉私活的情况，如果网络货运企业没有获得第三方企业的ETC授权，那么开出的ETC发票需要冲红处理，并且网络货运企业要提供相关的说明文件，因为ETC费用实由第三方企业缴纳，并且ETC归属于第三方企业，所开出的ETC发票也归属于第三方企业。这种情况下，网络货运企业如果不提供相关说明文件，票根网会直接冲红，但对应的交易信息会被列入异常名录，多次列入异常名录，票根网会限制网络货运企业获取其他的ETC发票能力。"
      }
    ];
    return { fq, changeStatus, useEtcHandler };
  }
});
</script>
<style lang="less" scoped>
.ask {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background: #1890ff;
    border-radius: 50%;
    left: 10px;
    top: 5px;
  }
}
.filing > div {
  overflow: auto;
}
</style>
