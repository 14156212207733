<template>
  <div style="height:100%;" class="pb-24">
    <div class="my-24 ml-24 goBack" @click="goBack"><span>×</span>申请备案</div>
    <div
      class="mx-24 mb-24 bg-white pt-24 pb-24"
      style="box-sizing:border-box;overflow:auto;min-height:calc(100% - 74px);"
    >
      <a-form
        layout="inline"
        ref="formRef"
        :rules="rules"
        style="width:850px;margin:0 auto;"
        :label-col="{ style: 'width:110px' }"
        :wrapper-col="{ style: 'width:290px' }"
        :model="formState"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item label="发票抬头" name="name">
          <a-input v-model:value="formState.name" disabled />
        </a-form-item>
        <a-form-item label="企业税号" name="tax_number">
          <a-input v-model:value="formState.tax_number" disabled />
        </a-form-item>
        <a-form-item label="开户银行" name="buyer_bank">
          <a-input v-model:value="formState.buyer_bank" />
        </a-form-item>
        <a-form-item label="银行账号" name="buyer_bank_account">
          <a-input v-model:value="formState.buyer_bank_account" />
        </a-form-item>
        <!-- <a-form-item label="企业地址信息" name="address">
          <p-cascader
            placeholder="请选择省/市/区"
            v-model:value="formState"
            @change="handleCityCheck"
            :disabled="!!formStateCopy.area"
          />
        </a-form-item> -->
        <a-form-item label="详细地址" name="buyer_address">
          <a-input v-model:value="formState.buyer_address" />
        </a-form-item>
        <a-form-item label="单位电话" name="buyer_phone">
          <a-input v-model:value="formState.buyer_phone" />
        </a-form-item>
        <a-form-item label="负责人" name="emergency_contact">
          <a-input v-model:value="formState.emergency_contact" />
        </a-form-item>
        <a-form-item label="负责人电话" name="emergency_contact_phone">
          <a-input v-model:value="formState.emergency_contact_phone" />
        </a-form-item>
        <a-form-item label="法人姓名" name="legal_name">
          <a-input v-model:value="formState.legal_name" />
        </a-form-item>
        <a-form-item label="法人身份证号" name="legal_id_no">
          <a-input v-model:value="formState.legal_id_no" />
        </a-form-item>
        <a-form-item
          name="contract_type"
          style="width:100%;"
          :wrapper-col="{ style: 'padding-left:110px;' }"
        >
          <a-radio-group v-model:value="formState.contract_type">
            <a-radio :style="radioStyle" value="SIGN"
              >使用电子签章签署
              <a-button class="pa-0" type="link" @click="detailModal = true"
                >《ETC开票服务协议》</a-button
              >
            </a-radio>
            <!-- <a-radio :style="radioStyle" value="OFFLINE"
              >上传《ETC开票服务授权函》及《ETC开票服务承诺书》，点击<a
                :href="templateUrl"
                >此处</a
              >下载模板</a-radio
            > -->
          </a-radio-group>
          <!-- <div v-if="formState.contract_type === 'OFFLINE'">
            <div
              :class="{
                imgUploadBox: true,
                commitment: true,
                isUpload: formState.Offline1.length === 1
              }"
            >
              <template
                v-for="(item, index) in formState.Offline1"
                :key="index"
              >
                <p-uploader
                  :value="item"
                  @input="e => Offline1.uploadInputHandle(e, index)"
                  :size="{ w: '180px', h: '120px' }"
                  class="mx-8 mb-12"
                />
              </template>
              <p-uploader-trigger
                v-if="formState.Offline1.length < 1"
                @selected="Offline1.handleSelected"
                :fileSize="8"
                :size="{ w: '180px', h: '120px' }"
                class="mx-8 mb-12"
              />
            </div>
            <div
              :class="{
                imgUploadBox: true,
                authorization: true,
                isUpload: formState.Offline2.length === 1
              }"
            >
              <template
                v-for="(item, index) in formState.Offline2"
                :key="index"
              >
                <p-uploader
                  :value="item"
                  @input="e => Offline2.uploadInputHandle(e, index)"
                  :size="{ w: '180px', h: '120px' }"
                  class="mx-8 mb-12"
                />
              </template>
              <p-uploader-trigger
                v-if="formState.Offline2.length < 1"
                @selected="Offline2.handleSelected"
                :fileSize="8"
                :size="{ w: '180px', h: '120px' }"
                class="mx-8 mb-12"
              />
            </div>
          </div> -->
        </a-form-item>
        <a-form-item
          style="width:100%;text-align:center;"
          :wrapper-col="{ style: 'width:100%;' }"
        >
          <a-button class="ml-24" type="primary" html-type="submit">
            提交备案
          </a-button>
        </a-form-item>
      </a-form>
    </div>

    <!-- 合同详情弹窗 -->
    <xModal
      :w="781"
      title="查看合同"
      :visible="detailModal"
      :closable="false"
      :footer="null"
      @handleCancel="detailModal = false"
    >
      <template #content>
        <p class="content-detail" v-html="contractContent"></p>
      </template>
    </xModal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import { message, Radio } from "ant-design-vue";
import xModal from "@/components/xModal";
import { useEtcFilingApi /* useOssEtcQueryTemplateApi */ } from "@/apis/etc";
import { useQueryCompanyDetailApi } from "@/apis/account";
import { useContractPreviewApi } from "@/apis/contract.js";
// import { useBatchUpload } from "@/hooks/UseBatchUpload";
import { state } from "@/store/modules/user";

export default defineComponent({
  components: { xModal, aRadio: Radio, aRadioGroup: Radio.Group },
  setup(props, ctx) {
    const contractPreview = useContractPreviewApi();

    const formRef = ref();
    //api
    const EtcFiling = useEtcFilingApi();
    const QueryCompanyDetail = useQueryCompanyDetailApi();
    // const OssEtcQueryTemplate = useOssEtcQueryTemplateApi();
    const templateUrl = ref("");
    // OssEtcQueryTemplate({
    //   oss_usages: ["ETC_CONTRACT_TEMPLATE"],
    //   relation_ids: [0]
    // }).then(res => {
    //   console.log("res===>", res);
    //   templateUrl.value = res[0] && res[0].data;
    //   templateUrl.value = templateUrl.value.replace("http:", "https:");
    // });

    const formState = ref({
      name: state.companyName, //发票抬头
      tax_number: state.taxNumber, //税号
      buyer_bank: "", //开户银行
      buyer_bank_account: "", //银行账号
      address: "",
      province: "", //省
      city: "", //市
      area: "", //区
      buyer_address: "", //详细地址
      contact_phone: "", //单位电话
      buyer_phone: "", //单位电话2
      emergency_contact: "", //负责人
      emergency_contact_phone: "", //负责人电话
      contract_type: "", //合约类型 OFFLINE, SIGN
      oss: [], //OFFLINE时必填
      // Offline1: [],
      // Offline2: [],
      legal_name: "",
      legal_id_no: ""
    });
    const radioStyle = reactive({
      display: "block",
      height: "30px",
      lineHeight: "30px"
    });
    //validateInfos
    //企业地址验证规则
    let validateAddress = () => {
      if (
        formState.value.province &&
        formState.value.city &&
        formState.value.area
      ) {
        return Promise.resolve();
      } else {
        return Promise.reject("企业信息不能为空");
      }
    };
    //上传文件验证
    const validateContractType = () => {
      if (!formState.value.contract_type) {
        return Promise.reject("请选择签署方式");
      } else {
        if (formState.value.contract_type === "SIGN") {
          return Promise.resolve();
        } else {
          /* if (
            formState.value.Offline1.length === 1 &&
            formState.value.Offline2.length === 1
          ) {
            return Promise.resolve();
          } else {
            return Promise.reject("请上传文件");
          } */
        }
      }
    };
    //汉字验证规则[\u4e00-\u9fa5]
    const validateName = () => {
      // alert(111);
      if (!formState.value.legal_name) {
        return Promise.reject("姓名不能为空");
      } else {
        let reg = /^[\u4e00-\u9fa5]{2,4}$/;
        if (reg.test(formState.value.legal_name)) {
          return Promise.resolve();
        } else {
          return Promise.reject("姓名不正确");
        }
      }
    };

    const rules = {
      buyer_bank: [
        {
          required: true,
          message: "开户银行不能为空",
          trigger: "blur"
        }
      ],
      buyer_bank_account: [
        {
          required: true,
          message: "银行账号不能为空",
          trigger: "blur"
        }
      ],
      address: [
        {
          required: true,
          validator: validateAddress
        }
      ],
      buyer_address: [
        {
          required: true,
          message: "详细地址不能为空",
          trigger: "blur"
        }
      ],
      buyer_phone: [
        {
          required: true,
          message: "单位电话不能为空",
          trigger: "blur"
        }
      ],
      emergency_contact: [
        {
          required: true,
          message: "负责人不能为空",
          trigger: "blur"
        }
      ],
      emergency_contact_phone: [
        {
          required: true,
          message: "负责人电话不能为空",
          trigger: "blur"
        }
      ],
      contract_type: [
        {
          required: true,
          validator: validateContractType
        }
      ],
      legal_name: [
        {
          required: true,
          validator: validateName,
          message: "姓名不正确",
          trigger: "blur"
        }
      ],
      legal_id_no: [
        {
          required: true,
          message: "身份证号码不正确",
          pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
          trigger: "blur"
        }
      ]
    };
    //上传的数据
    let QueryCompanyDetailInfo = "";
    //备份数据  以判断是否禁用
    let formStateCopy = ref({});
    //页面信息
    QueryCompanyDetail({ company_id: state.companyId }).then(res => {
      QueryCompanyDetailInfo = JSON.parse(JSON.stringify(res));
      formStateCopy.value = res;
      console.log("formStateCopy===>", formStateCopy);
      Object.keys(formState.value).forEach(val => {
        if (res[val]) {
          formState.value[val] = res[val];
        }
      });
    });
    // 图片上传
    //handleSelected, uploadInputHandle
    /* const Offline1 = useBatchUpload(formState, "Offline1");
    const Offline2 = useBatchUpload(formState, "Offline2"); */
    // 省市区选择
    const handleCityCheck = value => {
      formState.value.address = value; //省
      formState.value.province = value[0]; //省
      formState.value.city = value[1]; //市
      formState.value.area = value[2]; //区
    };
    const handleFinish = () => {
      onSubmit();
    };
    const handleFinishFailed = errors => {
      console.log(errors);
    };
    const success = () => {
      message.success("提交成功！");
      ctx.emit("changeStatus", "PENDING");
      // setTimeout(() => {
      //   ctx.emit("changeStatus", "APPROVED");
      // }, 3000);
    };
    const error = () => {
      message.error("提交失败！");
    };
    const onSubmit = () => {
      Object.keys(formState.value).forEach(val => {
        if (formState.value[val]) {
          QueryCompanyDetailInfo[val] = formState.value[val];
        }
      });
      //处理上传数据
      /* QueryCompanyDetailInfo.oss = formState.value.Offline1.concat(
        formState.value.Offline2
      ); */
      QueryCompanyDetailInfo.buyer_name = formState.value.name;
      QueryCompanyDetailInfo.buyer_phone = formState.value.buyer_phone;
      QueryCompanyDetailInfo.contact_phone = formState.value.buyer_phone;
      QueryCompanyDetailInfo.contact = formState.value.emergency_contact;

      QueryCompanyDetailInfo.emergency_contact_phone =
        formState.value.emergency_contact_phone;
      QueryCompanyDetailInfo.buyer_tax_number = formState.value.tax_number;
      QueryCompanyDetailInfo.ops_type = "PUTONGHUOYUN";
      console.log("上传数据", QueryCompanyDetailInfo);
      //   EtcFiling(QueryCompanyDetailInfo);
      EtcFiling(QueryCompanyDetailInfo).then(
        () => {
          success();
        },
        () => {
          error();
        }
      );
    };
    const resetForm = () => {
      formRef.value.resetFields();
    };
    //返回上一页goBack
    const goBack = () => {
      resetForm();
      ctx.emit("changeStatus", "NORECORD");
    };

    // 获取合同内容
    const contractContent = ref("");
    const detailModal = ref(false);

    (async () => {
      const data = await contractPreview({
        code: "ETC_UNIFIED_AGREEMENT"
      });
      contractContent.value = data.content;
    })();

    return {
      formState,
      rules,
      detailModal,
      contractContent,
      resetForm,
      handleFinish,
      handleFinishFailed,
      handleCityCheck,
      radioStyle,
      // Offline1,
      // Offline2,
      templateUrl,
      formRef,
      goBack,
      formStateCopy
    };
  }
});
</script>
<style scoped lang="less">
/* 表单样式调整 */
:deep(.ant-form-inline .ant-form-item) {
  margin-bottom: 22px;
}
:deep(.ant-input) {
  border-radius: 4px;
}
//取消冒号
:deep(.ant-form-item-label > label) {
  margin-right: 16px;
  &::after {
    display: none;
  }
}
/* 上传样式调整 */
.imgUploadBox {
  display: inline-block;
  vertical-align: top;
  line-height: 0;
}
:deep(.ant-upload.ant-upload-select-picture-card) {
  width: 100%;
  height: 100%;
}
// 图片宽高
:deep(.image-show) {
  max-height: 102px;
  max-width: 178px;
}
:deep(.ant-upload-text) {
  display: none;
}
.commitment :deep(.ant-upload.ant-upload-select-picture-card) {
  background: url("http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/etc/commitment.png")
    center/cover;
}
.authorization :deep(.ant-upload.ant-upload-select-picture-card) {
  background: url("http://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/vas/admin/etc/authorization.png")
    center/cover;
}
:deep(.w-upload) {
  display: inline-block;
}
.isUpload :deep(.ant-upload.ant-upload-select-picture-card > .ant-upload) {
  background-color: #fff;
}

:deep(.ant-upload.ant-upload-select-picture-card) {
  overflow: hidden;
  position: relative;
  &:hover {
    .shadow-bg {
      display: flex;
    }
    .delete-icon {
      display: block;
    }
  }
  .delete-icon {
    display: none;
    background: #3d7fff;
    color: white;
    position: absolute;
    right: -16px;
    top: -16px;
    width: 32px;
    height: 32px;
    line-height: 44px;
    text-align: left;
    padding-left: 4px;
    font-size: 16px;
    border-radius: 50%;
    z-index: 2;
  }
  .shadow-bg {
    background-color: rgba(0, 0, 0, 0.25);
    display: none;
    flex-direction: column;
    justify-content: center;
    color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    .anticon-eye-o {
      color: white;
    }
  }
}
:deep(.ant-upload-disabled) {
  .delete-icon {
    display: none !important;
  }
}
.goBack {
  display: inline-block;
  line-height: 22px;
  cursor: pointer;
  span {
    font-size: 26px;
    margin-right: 16px;
  }
}
//禁用字号color #4d4d4d
:deep(.ant-input[disabled]) {
  color: #4d4d4d;
}
.content-detail {
  display: flow-root;
}
</style>
